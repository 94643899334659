@import '@/core/tamagoshi/scss';

.container {
  background-color: $n0;
  color: $n800;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: $spacingLg 0;
  position: relative;

  @include size-below('md') {
    padding: 20px $spacingMd;
    justify-content: flex-start;
  }
}

.block {
  @include size-above('md') {
    padding-left: 348px;
    width: 1060px;
    margin-right: 0;
    position: relative;
  }
}

.illustration {
  position: absolute;
  left: 140px;
  top: 50%;
  margin-top: -70px;

  @include size-below('md') {
    display: none;
  }
}

.inputBlock {
  width: 563px;

  @include size-below('lg') {
    width: 479px;
  }

  @include size-below('md') {
    width: inherit;
  }
}

.title {
  @include text('body1', 'bold');
  display: inline-block;
  width: 100%;
}

.subTitle {
  @include text('body2', 'semiBold');
  display: inline-block;
  width: 100%;
}

.form {
  margin: $spacingMd 0 $spacingXs;

  div:first-child {
    margin-left: 0;
    border: none;
    border-radius: $spacingXs;
  }

  .container {
    width: 100%;
  }

  input {
    font-size: $textBody1FontSize;
    height: 100%;
    border: 1px solid $n400;
    border-radius: $spacingXs 0 0 $spacingXs;
  }
}

.subText {
  font-size: $spacingSm;
  color: $n700;
}

.subTextLink {
  text-decoration: underline;
}
