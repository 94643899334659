@use '/src/domains/core/tamagoshiv2/tokens/colors';
@import '../scss';

.message {
  @include text('small');
  color: $n700;
  display: flex;
  font-family: $fontFamily;
  margin: $spacingXXs 0 0;
  padding: 0;

  .icon {
    height: 16px;
  }

  .icon + span {
    padding-left: $spacingXXs;
  }

  &.danger {
    color: $r500;
  }

  &.info {
    color: $b500;
  }

  &.success {
    color: colors.$success-backgroundTertiary;
  }

  &.warning {
    color: $o500;
  }
}
