@use '/src/domains/core/tamagoshiv2/tokens/colors';
@import '../scss';

// stylelint-disable-next-line block-no-empty
@keyframes onAutoFillStart {
}

// stylelint-disable-next-line block-no-empty
@keyframes onAutoFillCancel {
}

.container {
  color: $n800;
  font-size: 12px;
  font-family: $fontFamily;
  margin: 4px;
  width: calc(100% - 8px);
}

.input {
  font-family: $fontFamily;
  width: 100%;
  padding: 16px 16px 0;
  box-sizing: border-box;
  color: $n800;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  height: 46px;
  border: none;
  border-radius: 4px;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill {
    animation-name: onAutoFillStart;
    transition: background-color 50000s ease-in-out 0s;
  }

  &:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
  }

  &[type='search'] {
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-appearance: textfield; // Fix a display bug on Safari
  }
}

.label {
  position: absolute;
  top: 14px;
  left: 16px;
  right: 16px;
  margin: 0;
  pointer-events: none;
  user-select: none;
  transition: 0.2s ease all;
  color: $n700;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;

  &.on {
    top: 8px;
    font-size: 12px;
    line-height: 16px;
  }

  &.required:after {
    content: '*';
    display: inline-block;
    color: $r500;
    padding-left: $spacingXXs;
  }
}

.content {
  position: relative;
  display: flex;
  background-color: $n0;
  border: 1px solid $n400;
  border-radius: 4px;
  height: 48px;

  &.focused,
  &:focus-within {
    box-shadow: 0 0 4px 0 rgba(12, 25, 58, 0.3);
  }

  &.disabled {
    background-color: $n200;

    .label {
      color: $n500;
    }
  }

  &.noLabel .input {
    padding: 8px 16px;
  }

  &.error {
    border-color: $r500;
  }

  &.success {
    border-color: colors.$success-backgroundTertiary;
  }
}

.wrapper {
  position: relative;
  width: 100%;
}
